import { useRouter } from "next/router";

import { URLS } from "@/constants";

export const IndexPage: React.FC = () => {
  const router = useRouter();

  router.push(URLS.home);
  return null;
};

export default IndexPage;
